<template>
  <div id="app">
    <van-nav-bar
      :title="headerTitle"
      fixed
      v-if="headerTitle != '河南省第二人民医院'"
      safe-area-inset-top
    >
      <template #left>
        <div class="navbar-left">
          <span @click="gotoBack"
            ><img src="./assets/img/home/gotoBack.png" alt=""
          /></span>
          <span @click="gotoHome"
            ><img src="./assets/img/home/gotoHome.png" alt=""
          /></span>
        </div>
      </template>
    </van-nav-bar>
    <div
      :style="{
        marginTop: headerTitle != '河南省第二人民医院' ? '46px' : '0px',
      }"
    >
      <router-view />
    </div>
    <loadingVue v-if="$store.state.LOADING"></loadingVue>
  </div>
</template>

<script>
import loadingVue from "./components/util/loading.vue";
import { mapGetters } from "vuex";
export default {
  components: { loadingVue },
  computed: {
    ...mapGetters(["headerTitle"]),
  },
  methods: {
    gotoBack() {
      this.$router.go(-1);
    },
    gotoHome() {
      this.$router.push("/treatmentServices");
    },
    getSysConfig() {
      this.$http.get("/api/content/config").then((res) => {
        window.localStorage.setItem("sysconfig", JSON.stringify(res.data));
      });
    },
  },
  mounted() {
    this.getSysConfig();
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.navbar-left {
  border: 1px solid #dcdfe6;
  border-radius: 15px;
  height: 30px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-left span {
  display: inline-block;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-left span:first-child {
  border-right: 1px solid #dcdfe6;
}
.navbar-left img {
  height: 15px;
  width: 15px;
}
</style>
