import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userid: "65e6fb15fd2476edfbad9cfcdebc9183",
    userInfo: {},
    appID: "wxe4daf84f333aa1ae",
    secret: "048147a6b49e55734f2f02b329fc3e27",
    LOADING: false,
    get showDeptTips() {
      return JSON.parse(sessionStorage.getItem("showDeptTips"));
    },
    set showDeptTips(value) {
      return sessionStorage.setItem("showDeptTips", value);
    },
    get deptActiveIndex() {
      return JSON.parse(sessionStorage.getItem("deptActiveIndex"));
    },
    set deptActiveIndex(value) {
      return sessionStorage.setItem("deptActiveIndex", value);
    },
    get queryActiveName() {
      return sessionStorage.getItem("queryActiveName");
    },
    set queryActiveName(value) {
      return sessionStorage.setItem("queryActiveName", value);
    },
    get headerTitle() {
      return sessionStorage.getItem("headerTitle");
    },
    set headerTitle(value) {
      return sessionStorage.setItem("headerTitle", value);
    },
    laboratoryDetails: JSON.parse(sessionStorage.getItem("laboratoryDetails")),

    wxUrl: sessionStorage.getItem("wxUrl"),
    model: sessionStorage.getItem("judgeModels"),
  },
  mutations: {
    SetUserId(state, data) {
      state.userid = data;
    },
    SetUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setAppID(state, appID) {
      state.appID = appID;
    },
    setSecret(state, secret) {
      state.secret = secret;
    },
    setShowDeptTips(state, show) {
      state.showDeptTips = show;
    },
    setDeptActiveIndex(state, show) {
      state.deptActiveIndex = show;
    },
    showLoading(state) {
      state.LOADING = true;
    },
    hideLoading(state) {
      state.LOADING = false;
    },
    setQueryActiveName(state, name) {
      state.queryActiveName = name;
    },
    setHeaderTitle(state, name) {
      state.headerTitle = name;
    },
    setLaboratoryDetails(state, name) {
      state.laboratoryDetails = name;
      sessionStorage.setItem("laboratoryDetails", JSON.stringify(name));
    },

    // 更改页面地址
    saveUrl(state, value) {
      state.wxUrl = value;
      sessionStorage.setItem("wxUrl", value);
    },
    // 判断手机类型
    judgeModels(state, value) {
      if (value) {
        state.model = "ios";
        sessionStorage.setItem("judgeModels", "ios");
      } else {
        state.model = "android";
        sessionStorage.setItem("judgeModels", "android");
      }
    },
  },
  getters: {
    showDeptTips: (state) => state.showDeptTips,
    headerTitle: (state) => state.headerTitle,
  },
  modules: {},
});
