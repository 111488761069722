import axios from "axios";
import qs from "qs";

const service = axios.create({
  timeout: 30000, // 超时时间
});

// 设置根请求地址
// eslint-disable-next-line no-undef
service.defaults.baseURL = healthCardRequestURL;

/***
 * 响应拦截器
 */
service.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    return Promise.reject(error);
  }
);

const healthHttpService = {
  // get 请求
  get(url, params = {}) {
    return new Promise((resolve, reject) => {
      service
        .get(url, {
          params: params,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // delete 请求
  delete(url, params) {
    return new Promise((resolve, reject) => {
      service
        .delete(url, {
          params: params,
        })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (err) {
          reject(err);
        });
    });
  },
  // post 请求以body的方式进行传值
  bodyPost(url, data = {}) {
    let sendObject = {
      url: url,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    return service(sendObject);
  },
  // post 表单请求
  post(url, data = {}) {
    let sendObject = {
      url: url,
      method: "post",
      data: data,
    };
    sendObject.data = qs.stringify(data);
    return service(sendObject);
  },
  // put 表单请求
  put(url, data = {}) {
    return service({
      url: url,
      method: "put",
      data: qs.stringify(data),
    });
  },
  // put body参数请求
  bodyPut(url, data = {}) {
    let sendObject = {
      url: url,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      transformRequest: [
        function (data) {
          // 在请求之前对data传参进行格式转换
          data = JSON.stringify(data);
          return data;
        },
      ],
      data: data,
    };
    return service(sendObject);
  },
};
/***
 * 封装http 异步请求
 * @param url
 * @param params
 * @returns {Promise<unknown>}
 */
export default healthHttpService;
