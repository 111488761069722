const guideRouter = [
    {
        path: '/hospitalProfile',
        name: 'hospitalProfile',
        meta: { title: '医院简介' },
        component: () => import('@/views/guide/hospitalProfile.vue')
    },
    {
        path: '/hospitalCulture',
        name: 'hospitalCulture',
        meta: { title: '医院文化' },
        component: () => import('@/views/guide/hospitalCulture.vue')
    },
    {
        path: '/physicianTeam',
        name: 'physicianTeam',
        meta: { title: '医师团队' },
        component: () => import('@/views/guide/team/physicianTeam.vue')
    },
    {
        path: '/searchPage',
        name: 'searchPage',
        meta: { title: '医师团队' },
        component: () => import('@/views/guide/team/searchPage.vue')
    },
    {
        path: '/deptPage',
        name: 'deptPage',
        meta: { title: '医师团队' },
        component: () => import('@/views/guide/team/deptPage.vue')
    },
    {
        path: '/doctorPage',
        name: 'doctorPage',
        meta: { title: '医生主页' },
        component: () => import('@/views/guide/team/doctorPage.vue')
    },
    {
        path: '/deptSpread',
        name: 'deptSpread',
        meta: { title: '科室分布' },
        component: () => import('@/views/guide/deptSpread.vue')
    },
    {
        path: '/routerHospital',
        name: 'routerHospital',
        meta: { title: '来院路线' },
        component: () => import('@/views/guide/routerHospital.vue')
    },
    {
        path: '/appointNotice',
        name: 'appointNotice',
        meta: { title: '预约须知' },
        component: () => import('@/views/guide/appointNotice.vue')
    },
    {
        path: '/hospitalNotice',
        name: 'hospitalNotice',
        meta: { title: '住院须知' },
        component: () => import('@/views/guide/hospitalNotice.vue')
    },
    {
        path: '/medicalNotice',
        name: 'medicalNotice',
        meta: { title: '医保须知' },
        component: () => import('@/views/guide/medicalNotice.vue')
    },
    {
        path: '/medicalDevice',
        name: 'medicalDevice',
        meta: { title: '医疗设备' },
        component: () => import('@/views/guide/device/medicalDevice.vue')
    },
    {
        path: '/medicalDetail',
        name: 'medicalDetail',
        meta: { title: '医疗设备' },
        component: () => import('@/views/guide/device/medicalDetail.vue')
    },
    {
        path: '/articleNew',
        name: 'articleNew',
        meta: { title: '健康资讯' },
        component: () => import('@/views/guide/article/article-new.vue')
    },
    {
        path: '/articleDetail',
        name: 'articleDetail',
        meta: { title: '健康资讯' },
        component: () => import('@/views/guide/article/article-detail.vue')
    },
    {
        path: '/hospitalNews',
        name: 'hospitalNews',
        meta: { title: '院内新闻' },
        component: () => import('@/views/guide/news/hospitalNews.vue')
    },
    {
        path: '/newsDetail',
        name: 'newsDetail',
        meta: { title: '院内新闻' },
        component: () => import('@/views/guide/news/newsDetail.vue')
    },
    {
        path: '/recruitList',
        name: 'recruitList',
        meta: { title: '人才招聘' },
        component: () => import('@/views/guide/recruit/recruitList.vue')
    },
    {
        path: '/recruitDetail',
        name: 'recruitDetail',
        meta: { title: '人才招聘' },
        component: () => import('@/views/guide/recruit/recruitDetail.vue')
    },
]

export default guideRouter;