<template>
  <div class="loading">
    <!-- <van-loading type="spinner" color="#00d48a" /> -->
  </div>
</template>

<script>
export default {
  name: "LOADING",
  data() {
    return {};
  },
};
</script>
<style scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 121;
  width: 100%;
  height: 100%;
  background: #fff;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.loading .van-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
