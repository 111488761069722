const ucenterRouter = [
    {
        path: '/invoice',
        name: 'invoice',
        meta: { title: '每日清单' },
        component: () => import('../../views/ucenter/invoice2.vue')
    },
    {
        path: '/doctor',
        name: 'doctor',
        meta: { title: '我的医生' },
        component: () => import('../../views/ucenter/Doctor.vue')
    },
    {
        path: '/doctorDetails',
        name: 'doctorDetails',
        meta: { title: '医生详情页' },
        component: () => import('../../views/ucenter/DoctorDetails.vue')
    },
    {
        path: '/register',
        name: 'register',
        meta: { title: '预约挂号记录' },
        component: () => import('../../views/ucenter/Register.vue')
    },
    {
        path: '/detail',
        name: 'detail',
        meta: { title: '预约挂号记录详情页' },
        component: () => import('../../views/ucenter/Detail.vue')
    },
    {
        path: '/help',
        name: 'help',
        meta: { title: '帮助反馈' },
        component: () => import('../../views/ucenter/Help.vue')
    },
    {
        path: '/investigation',
        name: 'investigation',
        meta: { title: '问卷调查' },
        component: () => import('../../views/ucenter/Investigation.vue')
    },
    {
        path: '/query',
        name: 'query',
        meta: { title: '报告查询' },
        component: () => import('../../views/ucenter/Query.vue')
    },
    {
        path: '/calcador',
        name: 'calcador',
        meta: { title: '检查报告详情页' },
        component: () => import('../../views/ucenter/Calcador.vue')
    },
    {
        path: '/report',
        name: 'report',
        meta: { title: '检验报告详情页' },
        component: () => import('../../views/ucenter/Report.vue')
    },
    {
        path: '/record',
        name: 'record',
        meta: { title: '挂号缴费记录' },
        component: () => import('../../views/ucenter/Record.vue')
    },
    {
        path: '/evaluate',
        name: 'evaluate',
        meta: { title: '就诊评价' },
        component: () => import('../../views/ucenter/Evaluate.vue')
    },
    {
        path: '/electronics',
        name: 'electronics',
        meta: { title: '电子发票' },
        component: () => import('../../views/ucenter/Electronics.vue')
    },
    {
        path: '/viewInvoicVe',
        name: 'viewInvoicVe',
        meta: { title: '查看发票' },
        component: () => import('../../views/ucenter/ViewInvoicVe.vue')
    },
    {
        path: '/OutpatientPaymentRecord',
        name: 'OutpatientPaymentRecord',
        meta: { title: '门诊缴费记录' },
        component: () => import('../../views/ucenter/OutpatientPaymentRecord.vue')
    },
    {
        path: '/PaymentRecordDetail',
        name: 'PaymentRecordDetail',
        meta: { title: '门诊缴费记录' },
        component: () => import('../../views/ucenter/PaymentRecordDetail.vue')
    },
    {
        path: '/nucleicPaymentRecordDtail',
        name: 'nucleicPaymentRecordDtail',
        meta: { title: '核酸缴费记录' },
        component: () => import('../../views/ucenter/nucleicPaymentRecordDtail.vue')
    },
]

export default ucenterRouter;