import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant"; // 引入vant
import "@/assets/css/base.css";
import "@/assets/css/index.css";
import "@/assets/icon/iconfont.css";
import "vant/lib/index.css"; // 引入vant样式
import "@/assets/css/global.css";
import "@/assets/css/icon.css"; // 引入图标样式
import { InputFloat } from "./directive/inputFloat";
import axios from "./util/request";
Vue.config.productionTip = false;
Vue.prototype.$http = axios;

import healthRequest from "./util/healthRequest";
Vue.prototype.$healthHttpService = healthRequest;

const APPID = "wxe4daf84f333aa1ae"; //订阅号
// const APPID = "wx04859d6db570ce01"; //服务号
const SECRET = "7bbddff6deda75ecb041d8c28a7bc8b3";
Vue.prototype.APPID = APPID;
Vue.prototype.SECRET = SECRET;
import "vant/lib/index.css"; //vant 组件库

Vue.directive("input-float", InputFloat);

Vue.use(Vant);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
