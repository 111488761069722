import Vue from 'vue'
import VueRouter from 'vue-router'
import guideRouter from "./modules/guides"
import ucenterRouter from "./modules/ucenter"
import serviceRouter from "./modules/services"
import healthCardRouter from "./modules/healthCard"
import store from "../store";

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        redirect: '/treatmentServices'
    },
    {
        path: '/treatmentServices',
        name: 'treatmentServices',
        meta: { title: '河南省第二人民医院' },
        component: () => import('../views/home/treatmentServices.vue')
    },
    {
        path: '/medicalGuide',
        name: 'medicalGuide',
        meta: { title: '河南省第二人民医院' },
        component: () => import('../views/home/medicalGuide.vue')
    },
    {
        path: '/personalCenter',
        name: 'personalCenter',
        meta: { title: '河南省第二人民医院' },
        component: () => import('../views/home/personalCenter.vue')
    },
    ...serviceRouter,
    ...guideRouter,
    ...ucenterRouter,
    ...healthCardRouter
]

const router = new VueRouter({
    routes
})

// 动态title
router.beforeEach((to, from, next) => {//beforeEach是router的钩子函数，在进入路由前执行
    if (to.meta.title) {//判断是否有标题
        document.title = "河南省第二人民医院"
        store.commit("setHeaderTitle",to.meta.title)
    }
    next()  //执行进入路由，如果不写就不会进入目标页
})
export default router
