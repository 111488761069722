/*
 * @Author: your name
 * @Date: 2021-11-09 17:10:16
 * @LastEditTime: 2021-11-09 17:16:33
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \新建文件夹 (8)\src\router\modules\services.js
 */
const serviceRouter = [
  {
    path: "/dept",
    name: "dept",
    meta: { title: "选择科室" },
    component: () => import("@/views/appoint/dept.vue"),
  },
  {
    path: "/doctors",
    name: "doctors",
    meta: { title: "预约医生" },
    component: () => import("@/views/appoint/doctors.vue"),
  },
  {
    path: "/appointSelect",
    name: "appointSelect",
    meta: { title: "预约确认" },
    component: () => import("@/views/appoint/appoint-select.vue"),
  },
  {
    path: "/appointSuccess",
    name: "appointSuccess",
    meta: { title: "预约确认" },
    component: () => import("@/views/appoint/appoint-success.vue"),
  },
  {
    path: "/appointWxPayPage",
    name: "appointWxPayPage",
    meta: { title: "付款详情" },
    component: () => import("@/views/appoint/wxPayPage.vue"),
  },
  {
    path: "/outpatientWxPayPage",
    name: "outpatientWxPayPage",
    meta: { title: "付款详情" },
    component: () => import("@/views/outpatientPayment/wxPayPage.vue"),
  },
  {
    path: "/patient",
    name: "patient",
    meta: { title: "就诊人管理" },
    component: () => import("@/views/patient/index.vue"),
  },
  {
    path: "/addPatient",
    name: "addPatient",
    meta: { title: "添加就诊人" },
    component: () => import("@/views/patient/addPatient.vue"),
  },
  {
    path: "/bindPatient",
    name: "bindPatient",
    meta: { title: "添加就诊人" },
    component: () => import("@/views/patient/bindPatient.vue"),
  },
  {
    path: "/cardInformations",
    name: "cardInformation",
    meta: { title: "就诊卡详情页" },
    component: () => import("@/views/patient/cardInformation.vue"),
  },
  {
    path: "/nucleicAcid",
    name: "nucleicAcid",
    meta: { title: "核酸检测" },
    component: () => import("@/views/nucleicAcid/nucleicAcid.vue"),
  },
  {
    path: "/enterPromise",
    name: "enterPromise",
    meta: { title: "核酸检测" },
    component: () => import("@/views/nucleicAcid/enterPromise.vue"),
  },
  {
    path: "/infoConfirm",
    name: "infoConfirm",
    meta: { title: "核酸检测" },
    component: () => import("@/views/nucleicAcid/infoConfirm.vue"),
  },
  {
    path: "/wxPayPage",
    name: "wxPayPage",
    meta: { title: "订单支付" },
    component: () => import("@/views/nucleicAcid/wxPayPage.vue"),
  },
  {
    path: "/outpatientPayment",
    name: "outpatientPayment",
    meta: { title: "门诊缴费" },
    component: () => import("@/views/outpatientPayment/outpatientPayment.vue"),
  },
  {
    path: "/paymentListEntry",
    name: "paymentListEntry",
    meta: { title: "门诊缴费详情" },
    component: () =>
      import("@/components/outpatientPayment/paymentListEntry.vue"),
  },
  {
    path: "/appointPayments",
    name: "appointPayments",
    meta: { title: "挂号缴费" },
    component: () => import("@/views/outpatientPayment/appointPayment.vue"),
  },
  {
    path: "/nucleicPayment",
    name: "nucleicPayment",
    meta: { title: "核酸缴费" },
    component: () => import("@/views/outpatientPayment/nucleicPayment.vue"),
  },
  {
    path: "/feverClinic",
    name: "feverClinic",
    meta: { title: "发热门诊核酸检测" },
    component: () => import("@/views/nucleicAcid/feverClinic/feverClinic.vue"),
  },
  {
    path: "/paySuccess",
    name: "paySuccess",
    meta: { title: "订单支付" },
    component: () => import("@/views/nucleicAcid/feverClinic/paySuccess.vue"),
  },
  {
    path: "/neonatalInfoConfirm",
    name: "neonatalInfoConfirm",
    meta: { title: "新生儿游泳" },
    component: () => import("@/views/neonatalSwimming/neonatalInfoConfirm.vue"),
  },
  {
    path: "/neonatalWxPayPage",
    name: "neonatalWxPayPage",
    meta: { title: "新生儿游泳" },
    component: () => import("@/views/neonatalSwimming/neonatalWxPayPage.vue"),
  },
  {
    path: "/dailyChecklist",
    name: "dailyChecklist",
    meta: { title: "每日清单" },
    component: () => import("@/views/inpatientServices/dailyChecklist.vue"),
  },
  {
    path: "/advancePayment",
    name: "advancePayment",
    meta: { title: "预交金充值" },
    component: () => import("@/views/inpatientServices/advancePayment.vue"),
  },
  {
    path: "/advancePaymentWxPayPage",
    name: "advancePaymentWxPayPage",
    meta: { title: "预交金充值" },
    component: () => import("@/components/wxPayPage/advancePayment.vue"),
  },
  {
    path: "/advancePaymentSuccess",
    name: "advancePaymentSuccess",
    meta: { title: "预交金充值" },
    component: () => import("@/components/wxPayPage/advancePaymentSuccess.vue"),
  },
  {
    path: "/antigenDectection",
    name: "antigenDectection",
    meta: { title: "抗原检测" },
    component: () => import("@/views/antigenDectection/antigenDectection.vue"),
  },
  {
    path: "/antigenDectectionWxPayPage",
    name: "antigenDectectionWxPayPage",
    meta: { title: "订单支付" },
    component: () => import("@/views/antigenDectection/wxPayPage.vue"),
  },
  {
    path: "/antigenDectectionPayment",
    name: "antigenDectectionPayment",
    meta: { title: "抗原缴费" },
    component: () =>
      import("@/views/antigenDectection/antigenDectectionPayment.vue"),
  },
  {
    path: "/enterAntigenResults",
    name: "enterAntigenResults",
    meta: { title: "抗原结果录入" },
    component: () =>
      import("@/views/antigenDectection/enterAntigenResults.vue"),
  },
  {
    path: "/antigenDectectionRecordDtail",
    name: "antigenDectectionRecordDtail",
    meta: { title: "抗原缴费记录" },
    component: () =>
      import("@/views/antigenDectection/antigenDectectionRecordDtail.vue"),
  },
  {
    path: "/accompanyingDetection",
    name: "accompanyingDetection",
    meta: { title: "陪护人员核酸检测" },
    component: () =>
      import("@/views/accompanyingDetection/accompanyingDetection.vue"),
  },
  {
    path: "/traditionalMedical",
    name: "traditionalMedical",
    meta: { title: "新冠预防中药" },
    component: () =>
      import("@/views/traditionalMedical/traditionalMedical.vue"),
  },
  {
    path: "/traditionalMedicalRecord",
    name: "traditionalMedicalRecord",
    meta: { title: "我的订单" },
    component: () =>
      import("@/views/traditionalMedical/traditionalMedicalRecord.vue"),
  },
  {
    path: "/traditionalMedicalRecordDetail",
    name: "traditionalMedicalRecordDetail",
    meta: { title: "我的订单" },
    component: () =>
      import("@/views/traditionalMedical/traditionalMedicalRecordDetail.vue"),
  },
];

export default serviceRouter;
