const healthCardRouter = [
    {
        path: "/healthCard",
        name: "healthCard",
        meta: {
            title: "电子健康卡管理",
            keepAlive: false,
        },
        component: () => import("@/views/healthCard/index.vue"),
    },
    {
        path: "/cardInformation",
        name: "cardInformation",
        meta: {
            title: "电子健康卡信息",
            keepAlive: false,
        },
        component: () => import("@/components/cardInformation.vue"),
    },
    {
        path: "/upgrade",
        name: "upgrade",
        meta: {
            title: "授权升级就诊卡",
            keepAlive: false,
        },
        component: () => import("@/views/healthCard/upgrade.vue"),
    },
    {
        path: "/addCardOne",
        name: "addCardOne",
        meta: {
            title: "电子健康卡注册(1/2)",
            keepAlive: false,
        },
        component: () => import("@/views/healthCard/addCardOne.vue"),
    },
    {
        path: "/addCardTwo",
        name: "addCardTwo",
        meta: {
            title: "电子健康卡注册(2/2)",
            keepAlive: false,
        },
        component: () => import("@/views/healthCard/addCardTwo.vue"),
    },
    {
        path: "/addCardThree",
        name: "addCardThree",
        meta: {
            title: "选择就诊卡",
            keepAlive: false,
        },
        component: () => import("@/views/healthCard/addCardThree.vue"),
    },
    {
        path: "/upgradeCard",
        name: "upgradeCard",
        meta: {
            title: "升级就诊卡",
            keepAlive: false,
        },
        component: () => import("@/views/healthCard/upgradeCard.vue"),
    },
]

export default healthCardRouter;
